import React from 'react';
import Loadable from 'react-loadable';

// eslint-disable-next-line import/no-cycle
// import DefaultLayout from './containers/DefaultLayout';

function Loading() {
  return <div>Loading...</div>;
}

const SalesOrder = Loadable({
  loader: () => import('./views/SalesOrder/SalesOrder'),
  loading: Loading,
});

const SalesOrderDetail = Loadable({
  loader: () => import('./views/SalesOrder/SalesOrderDetail/SalesOrderDetail'),
  loading: Loading,
});

const SalesOrderImport = Loadable({
  loader: () => import('./views/SalesOrder/SalesOrderImport/tableImport'),
  loading: Loading,
});

const InboundDelivery = Loadable({
  loader: () => import('./views/InboundDelivery/InboundDelivery'),
  loading: Loading,
});

const InboundDeliveryImport = Loadable({
  loader: () => import('./views/InboundDelivery/InboundDeliveryImport/tableImport'),
  loading: Loading,
});

const InboundDeliveryDetail = Loadable({
  loader: () => import('./views/InboundDelivery/InboundDeliveryDetail/InboundDeliveryDetail'),
  loading: Loading,
});

const Inventory = Loadable({
  loader: () => import('./views/Inventory/Inventory'),
  loading: Loading,
});

const InventoryDetail = Loadable({
  loader: () => import('./views/Inventory/InventoryDetail/InventoryDetail'),
  loading: Loading,
});

const InventoryImport = Loadable({
  loader: () => import('./views/Inventory/InventoryImport/tableImport'),
  loading: Loading,
});

const ReturnOrder = Loadable({
  loader: () => import('./views/Return/ReturnOrder'),
  loading: Loading,
});

const Dashboard = Loadable({
  loader: () => import('./views/Dashboard'),
  loading: Loading,
});

const BillOfMaterial = Loadable({
  loader: () => import('./views/BillOfMaterial/BillOfMaterial'),
  loading: Loading,
});

const BillOfMaterialDetail = Loadable({
  loader: () => import('./views/BillOfMaterial/BillOfMaterialDetail/BillOfMaterialDetail'),
  loading: Loading,
});

const NotAuthorized = Loadable({
  loader: () => import('./views/not-authorized'),
  loading: Loading,
});

const routes = [
  //{ path: '/', exact: true, name: 'Sales Order', component: SalesOrder },
  { path: '/not-authorized', exact: true, name: 'Not authorized', component: NotAuthorized },
  //{ path: '/dashboard', exact: true, name: 'Dashboard', component: Dashboard },
  { path: '/sales-order', exact: true, name: 'Sales Order', component: SalesOrder },
  { path: '/sales-order/import', name: 'Sales Order Import', component: SalesOrderImport },
  { path: '/sales-order/:erpOrder/:id', name: 'Sales Order Detail', component: SalesOrderDetail },
  { path: '/inbound-delivery', exact: true, name: 'Inbound Delivery', component: InboundDelivery },
  { path: '/inbound-delivery/import', name: 'Inbound Delivery Import', component: InboundDeliveryImport },
  { path: '/inbound-delivery/:receiptId/:id', name: 'Inbound Delivery Detail', component: InboundDeliveryDetail },
  { path: '/inventory', exact: true, name: 'Inventory', component: Inventory },
  { path: '/inventory/import', exact: true, name: 'Inventory Import', component: InventoryImport },
  { path: '/inventory/:productId', exact: true, name: 'Inventory Detail', component: InventoryDetail },
  { path: '/inventory/:productId/:id', exact: true, name: 'Inventory Detail', component: InventoryDetail },
  {
    path: '/inventory/:productId/sales-order/:erpOrder/:id',
    exact: true,
    name: 'Inventory Detail',
    component: InventoryDetail,
  },
  {
    path: '/inventory/:productId/inbound-delivery/:erpOrder/:id',
    exact: true,
    name: 'Inventory Detail',
    component: InventoryDetail,
  },
  { path: '/return', exact: true, name: 'Return', component: ReturnOrder },
  { path: '/bom', exact: true, name: 'Bill of Materials', component: BillOfMaterial },
  { path: '/bom/:itemCode', exact: true, name: 'Bill of Materials Detail', component: BillOfMaterialDetail },
];

export default routes;
