import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { HashRouter, Switch } from 'react-router-dom';

// Styles
// CoreUI Icons Set
// import '@coreui/icons/css/coreui-icons.min.css';
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import './scss/style.css';

// Containers
import { DefaultLayout } from './containers';

import ProtectedRoute from './components/ProtectedRoute';
import { getAccessTokenData, checkTokenValid } from './helpers/tokenHelper';
import { loginWithAzureB2C, getAccessToken } from './helpers/externalLoginHelper';
import Authentication from './auth/Authentication';
import { afterLogin, getUserPermissions } from './modules/User/actions';

import { APIHelper, StorageHelper } from './helpers';
import { storageName } from './constants/global';
import * as endPoint from './constants/endpoint';
import MaintenanceScreen from 'views/maintenance-screen';
import { SettingConstant } from 'constants/settings';

function App() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [maintenanceMode, setMaintenanceMode] = useState(false);

  useEffect(() => {
    let params = {},
      queryString = location.search,
      regex = /([^&=]+)=([^&]*)/g,
      m;
    while ((m = regex.exec(queryString))) {
      params[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);
    }

    if (params['code'] || queryString.includes('unauthorized')) {
      handleNormalMode(params, queryString);
    } else {
      StorageHelper.setStorage(SettingConstant.CLIENT_SECRET, SettingConstant.CLIENT_SECRET_VALUE);
      APIHelper.getRequest(`${endPoint.environmentVariables}`)
        .then((res) => {
          if (res.clientSecret) {
            StorageHelper.setStorage(SettingConstant.CLIENT_SECRET, res.clientSecret);
          }

          if (res.maintenanceMode) {
            setMaintenanceMode(true);
          } else {
            handleNormalMode(params, queryString);
          }
        })
        .catch(() => handleNormalMode(params, queryString));
    }
  }, []);

  const handleNormalMode = (params, queryString) => {
    const code = params['code'];
    if (queryString.includes('unauthorized')) {
      loginWithAzureB2C();
    } else if (code) {
      getAccessToken(code);
    } else {
      checkTokenValid()
        .then((valid) => {
          if (valid) {
            const company = StorageHelper.getStorage(storageName.activeCompany);
            const data = getAccessTokenData();
            if (data) {
              Authentication.setAuthenticate({
                name: data.name,
                email: data.emails && data.emails.length > 0 ? data.emails[0] : '',
                company: company || data.jobTitle,
                //role:data.extension_ExpressUser,
              });
            }
            if (!company && data) {
              dispatch(afterLogin());
            }

            dispatch(getUserPermissions());

            setIsLoading(false);
          } else {
            loginWithAzureB2C();
          }
        })
        .catch((error) => console.log(error));
    }
  };

  if (maintenanceMode) return <MaintenanceScreen />;

  if (isLoading) return null;

  return (
    <HashRouter>
      <Switch>
        <ProtectedRoute path="/" name="Home" component={(props) => <DefaultLayout {...props} />} />
      </Switch>
    </HashRouter>
  );
}

export default App;
