import { SettingConstant } from '../constants/settings';

const endPointPrefix = SettingConstant.APP_SERVICE_URL;

export const environmentVariables = `${endPointPrefix}environment-variables`;

export const user = {
  get: 'user/session',
  post: 'user/session',
  getUserPermissions: `${SettingConstant.UAM_SERVICE_URL}UserPermissions`,
};

export const filterCriteria = { list: `${endPointPrefix}FilterCriteria` };

export const product = {
  post: `${endPointPrefix}item`,
  put: `${endPointPrefix}item`,
  list: `${endPointPrefix}ProductList?activeonly=Y`,
  search: `${endPointPrefix}products`,
  get: `${endPointPrefix}ProductDetails`,
  movement: `${endPointPrefix}ProductStockMovement`,
};

export const salesOrderSearch = { list: `${endPointPrefix}OrderSearch` };

export const shipment = {
  list: 'shipment',
  post: `${endPointPrefix}shipment`,
  put: `${endPointPrefix}shipment`,
  getDeliveryInformation: `${endPointPrefix}shipment_information`,
  getProductInformation: `${endPointPrefix}shipment_information`,
  delete: `${endPointPrefix}order`,
};

export const inbound = { list: `${endPointPrefix}asnsearch` };

export const receipt = {
  list: 'receipt',
  post: `${endPointPrefix}receipt`,
  put: `${endPointPrefix}receipt`,
  getDeliveryInformation: `${endPointPrefix}AsnDetail`,
  getProductInformation: `${endPointPrefix}AsnProductDetail`,
  delete: `${endPointPrefix}Asns`,
};

export const returns = {
  list: `${endPointPrefix}returns`,
};

export const company = { list: `${endPointPrefix}CompanyList` };

export const dashboard = {
  inbound: `${endPointPrefix}Dashboard_Inbound_Summary`,
  order: `${endPointPrefix}Dashboard_Orders_Summary`,
  storage: `${endPointPrefix}Dashboard_Storage_Snapshot`,
};

export const bom = {
  post: `${endPointPrefix}bom`,
  put: `${endPointPrefix}bom`,
  list: `${endPointPrefix}BOMList`,
  search: `${endPointPrefix}Boms`,
  getDetail: `${endPointPrefix}BomDetails`,
  deleteDetail: `${endPointPrefix}BomDetails`,
};
