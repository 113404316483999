import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, NavLink } from 'react-router-dom';
import { Container, Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import IdleTimer from 'react-idle-timer';

import { CSidebar } from '@coreui/react';
import routes from '../../routes';
import Authentication from '../../auth/Authentication';
import * as global from '../../constants/global';

import { getAllFilterCriteria, setSearch } from '../../modules/Filter/actions';
import { getAllProducts } from '../../modules/Product/actions';
import { setActiveCompany, setActiveCompanyLabel, afterLogout } from '../../modules/User/actions';
import { CommonHelper, StorageHelper } from '../../helpers';

import logo from '../../assets/img/brand/estore_logistics_logo.svg';
import ReactGA from 'react-ga4';

const { storageName } = global;

class DefaultLayout extends Component {
  constructor(props) {
    super(props);

    this.idleTimer = null;
    this.state = {
      modalUserList: false,
      selectedCompany: null,
      disabledApplyBtn: true,
      disabledCustomerSelect: false,
      inventoryNavGroupCls: '',
      isLoading: false,
    };
    this.sidebarRef = React.createRef();
  }

  async componentDidMount() {
    const { isSuperUser, activeCompany, companies } = this.props;
    if (isSuperUser && companies.length > 0) {
      const filter = companies.filter((company) => company.COMPANY.toLowerCase() === activeCompany.toLowerCase());
      if (filter.length > 0) {
        const selected = filter[0];
        this.setState({
          selectedCompany: { value: selected.COMPANY, label: `${selected.COMPANY} - ${selected.NAME}` },
        });
      }
    }

    await this.loadNeededData();

    document.addEventListener('mousedown', this.clickOut);
  }

  async loadNeededData() {
    const { activeCompany, products, onGetAllProducts, filterCriteria, onGetAllFilterCriteria } = this.props;

    if (activeCompany !== 'Internal User' && (products.length === 0 || filterCriteria.length === 0)) {
      this.setState({
        isLoading: true,
      });
      if (products.length === 0) await onGetAllProducts();

      if (filterCriteria.length === 0) await onGetAllFilterCriteria();

      this.setState({
        isLoading: false,
      });
    }
  }

  filterCompanyList = (inputValue) => {
    const { companies } = this.props;
    const companyListOptions = companies.map((list) => ({
      value: list.COMPANY,
      label: `${list.COMPANY} - ${list.NAME}`,
    }));
    let filterList = companyListOptions;

    if (companyListOptions.length > 0 && inputValue !== '') {
      filterList = companyListOptions.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
    }

    return filterList;
  };

  loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(this.filterCompanyList(inputValue));
    }, 1000);
  };

  onActive = () => {
    if (this.idleTimer.getRemainingTime() === 0) {
      this.logout();
    }
  };

  logout = () => {
    const { history, onAfterLogout } = this.props;

    // reset local storage
    StorageHelper.deleteStorage(storageName.inventoryNavGroupCls);
    StorageHelper.deleteStorage(storageName.inventoryNavGroupActive);

    onAfterLogout();
    Authentication.signOut();
    history.push('/');
  };

  navLinkMenu = (e) => {
    const isActive = document.getElementsByClassName('nav-link active');
    if (isActive.length > 0) {
      for (let i = 0; i < isActive.length; i += 1) {
        isActive[i].classList.remove('active');
      }
      e.currentTarget.classList.add('active');
    } else if (isActive.length < 1) {
      e.currentTarget.classList.add('active');
    }
  };

  clearSearchText = () => {
    const { onSetSearch } = this.props;
    onSetSearch('%');
  };

  toggleModalUserList = (e) => {
    if (e && e.type == 'click') {
      e.preventDefault();
    }
    const { disabledCustomerSelect } = this.state;
    const { isSuperUser } = this.props;
    if (isSuperUser && !disabledCustomerSelect) {
      this.setState((prevState) => ({ modalUserList: !prevState.modalUserList }));
    }
  };

  setCustomer = (selected) => {
    let selectedCompany = null;
    if (selected) selectedCompany = selected;

    this.setState({ selectedCompany, disabledApplyBtn: false });
  };

  applyCustomer = async () => {
    this.setState({ disabledApplyBtn: true, disabledCustomerSelect: true });

    const { selectedCompany } = this.state;
    const { user, onSetActiveCompany, onSetActiveCompanyLabel, onGetAllFilterCriteria, onGetAllProducts } = this.props;
    const company = selectedCompany ? selectedCompany.value : user.company;
    const companyLabel = selectedCompany ? selectedCompany.label.split(' - ') : '';
    onSetActiveCompany(company);
    onSetActiveCompanyLabel(companyLabel[1]);
    await Promise.all([onGetAllFilterCriteria(), onGetAllProducts()]);

    this.setState({ disabledCustomerSelect: false }, () => {
      this.toggleModalUserList();
    });
  };

  getProfileName = () => {
    const { user, isSuperUser, activeCompany } = this.props;
    let profileName = activeCompany;

    if (!user) {
      this.logout();
      return;
    }

    if (isSuperUser && activeCompany !== user.company) {
      profileName = (
        <>
          <div className="mb-1">{user.name}</div>
          <div style={{ color: '#fde80f' }}>{activeCompany}</div>
        </>
      );
    } else if (user.name !== '') {
      profileName = user.name;
    }

    return profileName;
  };

  clickOut = (eve) => {
    if (this.sidebarRef.current && !this.sidebarRef.current.contains(eve.target) && window.innerWidth < 992) {
      let sidebarWrapper = document.getElementsByClassName('sidebar-show');
      for (let i = 0; i < sidebarWrapper.length; i++) {
        this.sidebarRef.current.dataset.sidebarToggler = 'false';
        sidebarWrapper[i].classList.remove('sidebar-show');
      }
    }
  };

  handleClickMenu = (menu) => {
    const { user, activeCompany } = this.props;
    ReactGA.event({
      category: 'menu_click_' + menu,
      action: 'menu_click',
      label: `${activeCompany} - ${user?.name || user?.email}`,
      value: menu,
    });

    this.loadNeededData();
  };

  render() {
    const { modalUserList, selectedCompany, disabledApplyBtn, disabledCustomerSelect, isLoading } = this.state;
    const { isSuperUser, activeCompanyLabel, userPermissions } = this.props;

    const shouldShowSaleOrder = CommonHelper.hasPermission(userPermissions, '', 'Read');
    const shouldShowInventory = CommonHelper.hasPermission(userPermissions, '', 'Read');
    const shouldShowInboundDelivery = CommonHelper.hasPermission(userPermissions, '', 'Read');
    const shouldShowReturns = CommonHelper.hasPermission(userPermissions, '', 'Read');
    const shouldShowBOMs = CommonHelper.hasPermission(userPermissions, '', 'Read');
    const shouldSwitchCompany = CommonHelper.hasPermission(userPermissions, '', 'CompanySelect');

    const isAuthorized = shouldShowSaleOrder || shouldShowInventory || shouldShowInboundDelivery || shouldShowReturns || shouldShowBOMs;

    const inventoryNavGroupCls = StorageHelper.getStorage(storageName.inventoryNavGroupCls) || '';
    const inventoryNavGroupActive = StorageHelper.getStorage(storageName.inventoryNavGroupActive) === 'true';

    return (
      <div>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          onActive={this.onActive}
          timeout={1000 * 60 * 60} // 1 hour
          debounce={250}
        />
        <div className="app">
          <div className="app-body">
            <CSidebar className="sidebar-fullheight" ref={this.sidebarRef} display="lg">
              <div className="p-0 sidebar-header">
                <img src={logo} alt="" className="navbar-brand-full" />
              </div>
              <nav className="sidebar-nav ps ps-container ps--active-y">
                <ul className="nav">
                  {/*<li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to="/dashboard"
                      onClick={() => {
                        this.handleClickMenu('dashboard');
                        StorageHelper.setStorage(storageName.inventoryNavGroupActive, false);
                      }}
                      id="menu_dashboard"
                    >
                      <i className="fa fa-home iconFA" aria-hidden="true"></i>
                      <label className="titleMenu">DASHBOARD</label>
                    </NavLink>
                    </li>*/}
                  {shouldShowSaleOrder && (
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to="/sales-order"
                        onClick={() => {
                          this.handleClickMenu('sales-order');
                          this.clearSearchText();

                          StorageHelper.setStorage(storageName.inventoryNavGroupActive, false);
                        }}
                        id="menu_sale_order"
                      >
                        {/* <span className="fa fa-bolt"></span> */}
                        <span className="iconU-salesorder" />
                        <label className="titleMenu">SALES ORDER</label>
                      </NavLink>
                    </li>
                  )}

                  {shouldShowInventory && (
                  <li className={`nav-group ${inventoryNavGroupCls} ${inventoryNavGroupActive ? 'active' : ''}`}>
                    <a
                      className="nav-link nav-group-toggle"
                      activeClassName="active"
                      onClick={() => {
                        StorageHelper.setStorage(
                          storageName.inventoryNavGroupCls,
                          this.state.inventoryNavGroupCls ? '' : 'show',
                        );
                        this.setState({ inventoryNavGroupCls: this.state.inventoryNavGroupCls ? '' : 'show' });
                        this.handleClickMenu('inventory');
                        this.clearSearchText();
                      }}
                      id="menu_inventory"
                    >
                      <span className="iconU-inventory" />
                      <label className="titleMenu">INVENTORY</label>
                    </a>
                    <ul className="nav-group-items">
                      <li className="nav-item">
                        <NavLink
                          className="nav-link"
                          activeClassName="active"
                          to="/inventory"
                          onClick={() => {
                            this.clearSearchText();

                            StorageHelper.setStorage(storageName.inventoryNavGroupActive, true);
                          }}
                        >
                          <label className="titleMenu">PRODUCTS</label>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          className="nav-link"
                          activeClassName="active"
                          to="/bom"
                          onClick={() => {
                            this.clearSearchText();

                            StorageHelper.setStorage(storageName.inventoryNavGroupActive, true);
                          }}
                        >
                          <label className="titleMenu">BILL OF MATERIALS</label>
                        </NavLink>
                      </li>
                    </ul>
                    </li>
                    )}

                  {/* {shouldShowInventory && (
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to="/inventory"
                        onClick={() => {
                          this.handleClickMenu('inventory');
                          this.clearSearchText();

                          StorageHelper.setStorage(storageName.inventoryNavGroupActive, false);
                        }}
                      >
                        <span className="iconU-inventory" />
                        <label className="titleMenu">INVENTORY</label>
                      </NavLink>
                    </li>
                  )} */}

                  {shouldShowInboundDelivery && (
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to="/inbound-delivery"
                        onClick={() => {
                          this.handleClickMenu('inbound-delivery');
                          this.clearSearchText();

                          StorageHelper.setStorage(storageName.inventoryNavGroupActive, false);
                        }}
                        id="menu_inbound_delivery"
                      >
                        <span className="iconU-inbounddelivery" />
                        <label className="titleMenu">INBOUND DELIVERY</label>
                      </NavLink>
                    </li>
                  )}

                  {shouldShowReturns && (
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to="/return"
                        onClick={() => {
                          this.handleClickMenu('return');
                          this.clearSearchText();

                          StorageHelper.setStorage(storageName.inventoryNavGroupActive, false);
                        }}
                        id="menu_return"
                      >
                        <span className="iconU-return" />
                        <label className="titleMenu">RETURNS</label>
                      </NavLink>
                    </li>
                  )}
                  {/* 
                  {shouldShowBOMs && (
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to="/bom"
                        onClick={() => {
                          this.handleClickMenu('bom');
                          this.clearSearchText();

                          StorageHelper.setStorage(storageName.inventoryNavGroupActive, false);
                        }}
                        id="menu_return"
                      >
                        <span className="iconU-bom" />
                        <label className="titleMenu">BOMS</label>
                      </NavLink>
                    </li>
                  )} */}
                </ul>
              </nav>
              <div className="p-0 sidebar-footer">
                <div className="row">
                  <div className="col">
                    <a href={global.freightEnquiryUrl} rel="noreferrer" target="_blank" className="border-username">
                      <div className="p-3 align-items-center d-flex help-hover">
                        <i className="fa fa-info iconFA" aria-hidden="true" style={{ padding: '0.3rem' }}></i>
                        <label className="ml-2 mb-0 title-help">Freight enquiry</label>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <a href={global.helpUrl} rel="noreferrer" target="_blank" className="border-username">
                      <div className="p-3 align-items-center d-flex help-hover">
                        <span className="help-icon" />
                        <label className="ml-2 mb-0 title-help">Help</label>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    {isSuperUser && shouldSwitchCompany ? (
                      <a
                        href="#select-customer"
                        className="border-username border-OBO"
                        onClick={(e) => this.toggleModalUserList(e)}
                      >
                        <div className="p-3 align-items-center d-flex help-hover">
                          <span className="iconCustom-username iconCustom-username-size" />
                          <span className="ml-2 mb-0 title-help">{this.getProfileName()}</span>
                        </div>
                      </a>
                    ) : (
                      <div className="border-username border-OBO">
                        <div className="p-3 align-items-center d-flex">
                          <span className="iconCustom-username iconCustom-username-size" />
                          <span className="ml-2 mb-0 title-help">{this.getProfileName()}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <a className="nav-link nav-logout-footer" href="#logout" onClick={this.logout}>
                      <div className="p-3 align-items-center d-flex help-hover">
                        <i className="fa fa-sign-out iconFA" aria-hidden="true" style={{ padding: '0.3rem' }}></i>
                        <label className="mb-0 title-help">Logout</label>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              {isSuperUser && shouldSwitchCompany ? (
                <Modal isOpen={modalUserList} className="super-user-dropdown">
                  <ModalHeader toggle={() => this.toggleModalUserList()}>Select Customer</ModalHeader>
                  <ModalBody>
                    <AsyncSelect
                      cacheOptions
                      loadOptions={this.loadOptions}
                      defaultOptions
                      value={selectedCompany}
                      placeholder="Enter Customer"
                      isFocused={false}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: '#00000',
                          pointerEvents: 'auto',
                          border: '2px solid #00000 !important',
                          borderColor: '#00000 !important',
                          boxShadow: 'none',
                          '&:hover': { borderColor: '#00000' },
                        }),
                        option: (provided) => ({
                          ...provided,
                          height: '20px',
                          paddingTop: 2,
                          paddingBottom: 0,
                          '&:hover': { backgroundColor: '#0e78d5' },
                        }),
                      }}
                      onChange={(selected) => this.setCustomer(selected)}
                      isDisabled={disabledCustomerSelect}
                    />
                    <Button
                      id="applyCustomer"
                      className="mt-3 float-right"
                      onClick={() => this.applyCustomer()}
                      disabled={disabledApplyBtn}
                    >
                      Apply
                    </Button>
                  </ModalBody>
                </Modal>
              ) : null}
            </CSidebar>

            <main className="main">
              {/* <AppBreadcrumb appRoutes={routes}/> */}
              {isLoading ? (
                <div className={'h-100'} style={{ textAlign: 'center', position: 'relative' }}>
                  <i className={'fa fa-refresh fa-10x fa-spin'} style={{ marginTop: '400px' }} />
                </div>
              ) : (
                <Container fluid>
                  <Switch>
                    {routes.map((route, idx) => {
                      return route.component ? (
                        <Route
                          // eslint-disable-next-line react/no-array-index-key
                          key={idx}
                          path={route.path}
                          exact={route.exact}
                          name={route.name}
                          render={(props) => <route.component {...props} />}
                        />
                      ) : null;
                    })}

                    {/*<Redirect from="/" to="/dashboard" />*/}
                    {isAuthorized ? (
                      <Redirect from="/" to={'/sales-order'} />
                    ) : (
                      <Redirect from="/" to={'/not-authorized'} />
                    )}
                  </Switch>
                </Container>
              )}
            </main>

            <div className="company-footer">
              <div className="row">
                <div className="col-sm text-left" style={{ paddingLeft: '6rem' }}>
                  {activeCompanyLabel}
                </div>
                <div className="col-sm">{`© eStore Logistics ${new Date().getFullYear()}`}</div>
                <div className="col-sm text-right">
                  <a
                    href={`${process?.env.PUBLIC_URL}/expressUserGuide.pdf`}
                    download="expressUserGuide.pdf"
                    className="mr-1"
                  >
                    User Guide
                  </a>
                  {process?.env.REACT_APP_VERSION}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DefaultLayout.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  isSuperUser: PropTypes.bool,
  activeCompany: PropTypes.string,
  activeCompanyLabel: PropTypes.string,
  companies: PropTypes.instanceOf(Array),
  products: PropTypes.instanceOf(Array),
  filterCriteria: PropTypes.instanceOf(Array),
  onGetAllFilterCriteria: PropTypes.func.isRequired,
  onGetAllProducts: PropTypes.func.isRequired,
  onSetActiveCompany: PropTypes.func.isRequired,
  onAfterLogout: PropTypes.func.isRequired,
  onSetSearch: PropTypes.func.isRequired,
  onSetActiveCompanyLabel: PropTypes.func.isRequired,
  userPermissions: PropTypes.instanceOf(Array),
};

DefaultLayout.defaultProps = {
  isSuperUser: false,
  activeCompany: '',
  activeCompanyLabel: '',
  companies: [],
  products: [],
  filterCriteria: [],
  userPermissions: [],
};

const mapStateToProps = ({ company, product, user, filter }) => ({
  user: user.profile,
  isSuperUser: user.isSuperUser,
  activeCompany: user.activeCompany,
  activeCompanyLabel: user.activeCompanyLabel,
  companies: company.data,
  products: product.data,
  filterCriteria: filter.criteria,
  userPermissions: user.userPermissions,
});

const mapDispatchToProps = (dispatch) => ({
  onGetAllFilterCriteria: () => dispatch(getAllFilterCriteria()),
  onSetActiveCompany: (company) => dispatch(setActiveCompany(company)),
  onSetActiveCompanyLabel: (companyLabel) => dispatch(setActiveCompanyLabel(companyLabel)),
  onGetAllProducts: () => dispatch(getAllProducts()),
  onAfterLogout: () => dispatch(afterLogout()),
  onSetSearch: (search) => dispatch(setSearch(search)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
